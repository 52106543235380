import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragScrollModule } from 'ngx-drag-scroll';
import {MatButtonModule} from '@angular/material/button';
import { RouterModule, Routes } from '@angular/router';

import { sanitizeHtmlPipe } from '../app/pipe/sanitize-html.pipe';

import { AppComponent } from './app.component';
import { AboutMeComponent } from './components/aboutme.component';
import { LandingComponent } from './components/landing.component';




const appRoutes: Routes = [
  { path: 'about-me', component: AboutMeComponent, data : {animation: 'AboutPage'} },
  { path: '',
    component: LandingComponent,
    pathMatch: 'full',
    data : {animation: 'LandingPage'}
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    AboutMeComponent,
    sanitizeHtmlPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
    FlexLayoutModule,
    DragScrollModule,
    MatButtonModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ],
  exports:[
    sanitizeHtmlPipe
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
