import { trigger, animate, style, query, transition } from '@angular/animations';

export const slideInAnimation =
    trigger('routeAnimations', [
        transition('LandingPage <=> AboutPage', [
            query(':enter', [
                style({ opacity: 0 }),
                animate(3000, style({ opacity: 1}))
            ], { optional: true })
        ])
    ]);