import { Component, OnInit, ViewChild } from '@angular/core';
import { Company } from '../class/company'
import { DragScrollComponent} from '../../../node_modules/ngx-drag-scroll'

export const COMPANIES: Company[] = [
  {
    id: 1,
    name: 'CACIB',
    location: 'Singapore',
    rangeDate: 'May 2017 to May 2019',
    position: 'TechLead - Contractor',
    logo : '../../assets/img/cacib.jpg',
    description: '<p>As a tech lead, my role was split into two major categories : <p>'
     +
     '<p>&bull; Coach, mentor and develop a team of 10 developers: </p>'
     +
     '<p style="margin-left:2em;">&rarr; Ensured to use the latest technologies/methodologies'
     +
     '<ul style="margin-left:2em;list-style-type:square;"><li>Angular 2+/.NET Core</li><li>TDD with unit and integration tests</li><li>Introduction to Entity Framework Code First</li><li>Moved from Sharepoint to Angular/.NET Core</li>'
     +
     '<li>Moved from TFS to Gitlab</li><li>Introduction to Merge Requests concepts</li><li>Created a code review guideline</li></ul>'
     +
     '</p>'
     +
     '<p style="margin-left:2em;">&rarr; Conducted design and code reviews of all projects</p>'
     +
     '<p style="margin-left:2em;">&rarr; Organized, presented sharing knowledge sessions (TDD, GitLab, Angular) <ul style="margin-left:2em;list-style-type:square;"><li>Knowlegde shared between Junior and Senior developers</li></ul> </p>'
     +
     '<p>&bull; Designed and built multiple software or web applications: </p>'
     +
     '<p style="margin-left:2em;">&rarr; Developed an ETL to detect any suspicious behavior against the financial market'
     +
     '<ul style="margin-left:2em;list-style-type:square;"><li>Designed, estimated and coordinated tasks with a team of 4 developers</li><li>Ensured consistency and maintainability across the application components</li></ul>'
     +
     '<p style="margin-left:4em;"><i class="fa fa-tags"></i> C#, .NET CORE, T-SQL, KAFKA, EF, SQL SERVER 2016</p>'
     +
     '</p>'
     +
     '<p style="margin-left:2em;">&rarr; Developed an application managing the proposal and approval of business solutions'
     +
     '<ul style="margin-left:2em;list-style-type:square;"><li>Led, designed, estimated and coordinated tasks with a team of 5 developers</li><li>Ensured consistency and maintainability across the application components</li></ul>'
     +
     '<p style="margin-left:4em;"><i class="fa fa-tags"></i> C#, .NET, Angular, TypeScript, T-SQL, EF, SQL SERVER 2016</p>'
     +
     '</p>'
     +
     '<p style="margin-left:2em;">&rarr; Revamped the search tab of a Sharepoint application'
     +
     '<ul style="margin-left:2em;list-style-type:square;"><li>Led, designed, estimated and coordinated tasks with a team of 3 developers</li><li>Ensured consistency and maintainability across the application components</li></ul>'
     +
     '<p style="margin-left:4em;"><i class="fa fa-tags"></i> C#, .NET, Angular, TypeScript, T-SQL, EF, Dapper, SQL SERVER 2008</p>'
     +
     '</p>'
  },
  {
    id: 2,
    name: 'RingRing',
    location: 'Brussels - Belgium',
    rangeDate: 'July 2016 to October 2016',
    position: 'Software Developer - Freelance',
    logo : '../../assets/img/ringring.jpg',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    id: 3,
    name: 'Finexkap',
    location: 'Paris - France',
    rangeDate: 'February 2016 to June 2016',
    position: 'Software Developer - Freelance',
    logo : '../../assets/img/finexkap.png',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    id: 4,
    name: 'Candriam',
    location: 'Brussels - Belgium | Paris - France',
    rangeDate: 'November 2015 to February 2016',
    position: 'Software Developer - Contractor',
    logo : '../../assets/img/candriam.jpg',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    id: 5,
    name: 'Youscribe',
    location: 'Paris - France',
    rangeDate: 'April 2014 to November 2015',
    position: 'Software Developer - Internal',
    logo : '../../assets/img/youscribe.png',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    id: 6,
    name: 'MRM//McCann',
    location: 'Paris - France',
    rangeDate: 'March 2013 to March 2014',
    position: 'Software Developer - Internal',
    logo : '../../assets/img/mrm.jpg',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    id: 7,
    name: 'Brocelia',
    location: 'Paris - France',
    rangeDate: 'January 2013 to March 2013',
    position: 'Software Developer - Internal',
    logo : '../../assets/img/brocelia.png',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    id: 8,
    name: '1000mercis / numberly',
    location: 'Paris - France',
    rangeDate: 'July 2007 to December 2012',
    position: 'Software Developer - Internal',
    logo : '../../assets/img/1000mercis.png',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  }
]

@Component({
  selector: 'app-root',
  templateUrl: './aboutme.component.html',
  styleUrls: ['./aboutme.component.css']
})


export class AboutMeComponent implements OnInit {
  companies = COMPANIES;
  selectedComp: Company;
  
  ngOnInit() {
    this.selectedComp = this.companies[0];
  }

  onSelect(comp: Company){
    this.selectedComp = comp;
  }

  @ViewChild('nav', {read: DragScrollComponent}) ds: DragScrollComponent;
  
  moveLeft() {
    this.ds.moveLeft();
  }
 
  moveRight() {
    this.ds.moveRight();
  }
}

