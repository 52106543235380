import { Component } from '@angular/core';
import {trigger, state, animate, style, transition} from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})

export class LandingComponent {
}